<template>
  <div role="main" style="height: calc(100vh - 105px)">
    <div id="itemBody" class="itemBody" data-section="true">
      <div class="container">
        <div class="instructions">
          <h1>IELTS General Training Reading</h1>
          <div class="preTestInfo">
            <p>Time: 1 hour</p>
            <h2>INSTRUCTIONS TO CANDIDATES</h2>
            <ul>
              <li>Answer <b>all</b> the questions.</li>
              <li>You can change your answers at any time during the test.</li>
            </ul>
            <h2>INFORMATION FOR CANDIDATES</h2>
            <ul>
              <li>There are 40 questions in this test.</li>
              <li>All questions carry one mark.</li>
              <li>The test clock will show you when there are 10 minutes and 5 minutes remaining.</li>
            </ul>
          </div>
          <div class="textCentre">
            <p class="info central">Do not click 'Start test' until you are told to do so.</p>
            <input type="submit" id="instructionsConfirm" value="Start test" class="button green" @click="$router.push({name: 'apps-academic-reading-main'})"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WritingInstruction"
}
</script>


